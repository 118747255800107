<template>

  <div>




    <div  v-if="specific && specific.length !== 0" class="mt-5" >

      <div class="card_container" >
        <div class="card_header mb-5" >
          <font>นักศึกษานิเทศเฉพาะ(นิเทศเอก)</font>
        </div>
        <v-row>
          <v-col v-for="(result,index) in specific" :key="result.id" xl="4" lg="6" md="12" xs="12" cols="12" >
            <StudentCard
                v-wow="{ 'animation-name': 'fadeIn','animation-duration': '1.'+(7+index+2)+'s'}"
                :callback="()=> GotoAssessment('specific',result)"
                :callback_asm="()=> GotoDetail(result.id)"
                :data="result"
                types="specific"
            />
          </v-col>
        </v-row>

      </div>
    </div>

    <div  v-if="general && general.length !== 0" class="mt-2" >

      <div class="card_container" >
        <div class="card_header mb-5" >
          <font>นักศึกษานิเทศทั่วไป</font>
        </div>
        <v-row>
          <v-col v-for="(result,index) in general" :key="result.id" xl="4" lg="6" md="12" xs="12" cols="12" >
            <StudentCard
                v-wow="{ 'animation-name': 'fadeIn','animation-duration': '1.'+(7+index+2)+'s'}"
                :callback="()=> GotoAssessment('general',result)"
                :callback_asm="()=> GotoDetail(result.id)"
                :data="result"
                types="general"
            />
          </v-col>
        </v-row>

      </div>
    </div>


    <div  v-if="teacher && teacher.length !== 0" class="mt-2" >

      <div class="card_container" >
        <div class="card_header mb-5" >
          <font>ข้อมูลนักศึกษาที่ดูแล <big><i class="fa fa-graduation-cap"></i></big>  </font>
        </div>
        <v-row>
          <v-col v-for="(result,index) in teacher" :key="result.id" xl="4" lg="6" md="12" xs="12" cols="12" >
            <StudentCard
                v-wow="{ 'animation-name': 'fadeIn','animation-duration': '1.'+(7+index+2)+'s'}"
                :callback="()=> GotoAssessment('teacher',result)"
                :callback_asm="()=> GotoDetail(result.id)"
                :data="result"
                types="teacher"
            />
          </v-col>
        </v-row>

      </div>
    </div>


    <div  v-if="manager && manager.length !== 0" class="mt-2" >

      <div class="card_container" >
        <div class="card_header mb-5" >
          <font>  ข้อมูลนักศึกษาภายในโรงเรียน  <big><i class="fa fa-school"></i></big> </font>
        </div>
        <v-row>
          <v-col v-for="(result,index) in manager" :key="result.id" xl="4" lg="6" md="12" xs="12" cols="12" >
            <StudentCard
                v-wow="{ 'animation-name': 'fadeIn','animation-duration': '1.'+(7+index+2)+'s'}"
                :callback="()=> GotoAssessment('manager',result)"
                :callback_asm="()=> GotoDetail(result.id)"
                :data="result"
                types="manager"
            />
          </v-col>
        </v-row>

      </div>
    </div>

  </div>


</template>
<script>
// @ is an alias to /src

import { getStudent} from "../lib/controller";
import {ResponseData} from "../lib/utility";
import StudentCard from "../components/StudentCard";
import {access_for} from "../const/api";

export default {
  name: 'Assessment',
  components:{
    StudentCard
  },
  data: () => ({
    is_student:true,
    users:JSON.parse(localStorage.getItem('user')),
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
    ],
    specific_raw:[],
    general_raw:[],
    teacher_raw:[],
    manager_raw:[],
    specific:[],
    general:[],
    teacher:[],
    manager:[],
    year_select:63,
    year_data:[
      {
        "label":"รหัสนักศึกษา 61",
        "id":61
      },
      {
        "label":"รหัสนักศึกษา 62",
        "id":62
      },
      {
        "label":"รหัสนักศึกษา 63",
        "id":63
      },
      {
        "label":"รหัสนักศึกษา 64",
        "id":64
      },
      {
        "label":"ทั้งหมด",
        "id":0
      }
    ],
  }),
  methods:{
    async GetData() {

      this.is_loading = true;
      console.log(this)

      let params = {
        type:this.users.type,
        access_for:access_for,
        id: this.users.type == 'manager' ? this.users.school_id : this.users.id,
      }

      await getStudent(params, (response) => {

        const {status, data} = ResponseData(response)

        switch (status) {
          case 200:

            this.specific = data.specific_lecturer;
            this.general = data.general_lecturer;
            this.teacher = data.teacher;
            this.manager = data.manager;

            this.specific_raw = this.specific;
            this.general_raw = this.general;
            this.teacher_raw = this.teacher;
            this.manager_raw = this.manager;

            //this.CheckYear();

            break;
          default:

            this.is_loading = false;
            break;
        }

      });
    },
    GotoAssessment(type,res){
      //console.log(res)
      this.$router.push({ path: "rate/"+type+"/"+res.id });
    },
    GotoDetail(id){
      //console.log(res)
      this.$router.push({ path: "detail/"+id });
    },
    CheckYear(){

      if(this.year_select == 0){
        this.specific = this.specific_raw;
        this.general = this.general_raw;
        this.teacher = this.teacher_raw;
        this.manager = this.manager_raw;
      }else{
        if(this.specific_raw){
          this.specific = this.specific_raw.filter((result)=> result.student_code.substr(0,2) == this.year_select )
        }
        if(this.general_raw){
          this.general = this.general_raw.filter((result)=> result.student_code.substr(0,2) == this.year_select )
        }

        if(this.teacher_raw){
          this.teacher = this.teacher_raw.filter((result)=> result.student_code.substr(0,2) == this.year_select )
        }

        if(this.manager_raw){
          this.manager = this.manager_raw.filter((result)=> result.student_code.substr(0,2) == this.year_select )
        }

      }


    },
  },
  mounted() {
    this.GetData();
  }
}
</script>
