<template>

  <v-container  class="custom-container-login" >
    <div class="login_container" >
      <div class="login-layout" >
        <v-row class="login-form" >
          <v-col  xl="6" lg="6" md="6" sm="12" xs="12" cols="12" >
            <div class="login-left-layout" >
              <p v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '1.5s'}" class="header-title" >ระบบนิเทศนักศึกษาฝึกวิชาชีพระหว่างเรียน 1</p>
              <img v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '1.7s'}" class="img-login" src="../assets/image/object.png" />
            </div>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="12" xs="12" cols="12" class="position_relation" >
            <div class="login-right-layout" >
              <img v-wow="{ 'animation-name': 'fadeInDown','animation-duration': '1.5s'}" src="../assets/image/logo.png" />
              <div class="form-control-custom" >
                <v-form
                    v-wow="{ 'animation-name': 'fadeInDown','animation-duration': '1.5s'}"
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                  <v-text-field
                      v-model="username"
                      :rules="[v => !!v || 'กรุณากรอกชื่อผู้ใช้งาน']"
                      label="ชื่อผู้ใช้งาน"
                      required
                      placeholder="ชื่อผู้ใช้งาน"
                  ></v-text-field>

                  <v-text-field
                      v-model="password"
                      :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[v => !!v || 'กรุณากรอกรหัสผ่าน']"
                      :type="show_password ? 'text' : 'password'"
                      label="รหัสผ่าน"
                      @click:append="show_password = !show_password"
                  ></v-text-field>
                  <div align="right" class="pt-1 pb-2" style="display: none" >
                    <div class="right-btn-link" >
                      <a href="/" >สมัครสมาชิก</a> /
                      <a href="/" >ลืมรหัสผ่าน</a>
                    </div>
                  </div>
                  <div align="center">
                    <div class="list_btn" >
                      <v-btn
                          class="mr-4 btn_login"
                          @click="validate"
                      >
                        เข้าสู่ระบบ
                      </v-btn>
                      <v-btn
                          class="mr-4 btn_reset"
                          @click="reset"
                      >
                        ล้างข้อมูล
                      </v-btn>
                    </div>

                    <font v-if="notfound" class="notfound" >ไม่พบชื่อผู้ใช้งาน</font>

                  </div>

                </v-form>

              </div>

            </div>
            <div class="link_system" >
              <a href="https://rmuasmapp.msksolution.net/" >internshipRMU</a>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>


</template>
<script>
// @ is an alias to /src

//import $ from "jquery";


import {auth} from "../lib/controller_auth";
import {ResponseData} from "../lib/utility";

export default {
  name: 'Home',
  data: () => ({
    password: '',
    username: '',
    valid: true,
    notfound: false,
    show_password: false,
    rules: {
      required: value => !!value || 'กรอกรหัสผ่าน.',
    },
    email_rules: [
      v => !!v || 'อีเมลต้องไม่เป็นค่าว่าง',
      v => /.+@.+\..+/.test(v) || 'รูปแบบอีเมลไม่ถูกต้อง',
    ],
  }),

  methods:{
    validate () {
      this.notfound = false;
      if(this.$refs.form.validate()){
        this.Login();
      }
    },
    async Login(){
      this.notfound = false;
      const param = {
        "username":this.username,
        "password":this.password,
      }


      //localStorage.setItem('user',JSON.stringify(param))
      //window.location = "/"






      await auth(param,(response)=>{
        const {status, data} = ResponseData(response)

        switch (status){
          case 200:

            this.$swal({
              title: "เข้าสู่ระบบสำเร็จ",
              text: "ยินดีต้อนรับ" + data.first_name+" "+data.last_name,
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              localStorage.setItem('user',JSON.stringify(data))
              window.location = "/"
            });


            break;
          default:

            this.$swal({
              title: "เข้าสู่ระบบไม่สำเร็จ",
              text: "ไม่พบชื่อผู้ใช้ในระบบ",
              icon: "warning",
              confirmButtonText: "ตกลง"
            });

         break;
        }

      });

    },
    reset () {
      this.$refs.form.reset()
    },
    CheckLogin(){
      const UserData = localStorage.getItem('user');
      if(UserData){
        console.log("Islogin")
        window.location = "/"
      }else{
        //console.log("Nologin")
      }
    }
  },
  mounted() {
    this.CheckLogin();
  }
}
</script>
<style scoped >
body{
  background: #E5E8F0;
}
.form_login{
  width: 50rem;
  height: auto;
  padding:3rem;
  background: #ffffff;
  border-radius: 0.5rem;
}
.login_container{
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #E5E8F0;
  padding: 2rem;

}
.notfound{
  font-size: 1rem;
  color: red;
}
.login-layout{
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  border: 5px solid rgb(255 255 255 / 69%);
}
.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  background: whitesmoke;
}
.list_btn{
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_login{
  background: #3fec53  !important;
  color: white !important;
}
.btn_reset{
  background: #d4103c !important;
  color: white !important;
}
.link_system{
  position: absolute;
  color: black;
  right: 20px;
  bottom: 20px;
  display: none;
}
.position_relation{
  position: relative;
}
</style>
