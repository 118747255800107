<template>
  <div class="list_card" @click="callback" >
    <v-row>
      <v-col class="thumb_list" xl="4" lg="4" md="12" cols="12" >
        <img class="thumb_profile" :src="baseUrl+'/'+JSON.parse(data.profile).path_thumb" />
      </v-col>
      <v-col xl="8" lg="8" md="12" cols="12" >
        <font><b>ชื่อ-นามสกุล:</b>{{ getSexlabel(data.sex) }}{{ data.first_name }} {{ data.last_name }} <br></font>
        <font v-if="data.school" ><b>โรงเรียน:</b>{{ data.school ? data.school : "-" }} <br></font>
        <font v-if="data.branch" ><b>สาขา:</b>{{ data.branch ? data.branch : "-" }} <br></font>
      </v-col>
    </v-row><br>
    <hr class="line" ><br>
    <v-row>
      <v-col xl="6" lg="12" md="12" sm="12" cols="12" >
        <div align="center" >
        <font><b>ภาคเรียนที่ 1</b></font>
        </div>
        <div class="list_box">
          <div class="box-data" >{{ rate_1 }}</div>
        </div>
      </v-col>
      <v-col xl="6" lg="12" md="12" sm="12" cols="12" >
        <div align="center" >
        <font><b>ภาคเรียนที่ 2</b></font>
        </div>
        <div class="list_box">
          <div class="box-data" >{{ rate_2 }}</div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import  {baseUrlMain } from "../const/api";
import {getData} from "../lib/controller";
import {ResponseData} from "../lib/utility";

export default {
  name: "PeopleCard",
  props:["callback","data","types","student_id"],
  data:()=>(
      {
        baseUrl:baseUrlMain,
        users: JSON.parse(localStorage.getItem('user')),
        rate_1:"-",
        rate_2:"-",
        rate_3:"-",
        rate_4:"-",
        rate_5:"-",
        rate_6:"-",
      }
  ),
  methods:{
  getSexlabel(sex){
    switch (sex){
      case "mr":
        return "นาย"
      case "ms":
        return "นางสาว"
      case "mrs":
        return "นาง"
    }
  },
    async GetData() {

      console.log(this.types)
      console.log(this.data)

      this.is_loading = true;

      let params = {
        "type":this.types,
        "student_id":this.student_id ? this.student_id : this.users.id ,
        "id":this.data.id,
      }

      await getData(params, (response) => {

        const {status, data} = ResponseData(response)

        let count = 0
        let rate_1 , rate_2 , rate_3 , rate_4 , rate_5 , rate_6;

        switch (status) {
          case 200:


            this.rate_data = data.rate;

            this.assessment = data.assessment;


            this.rate_data.forEach((result) => {
              result.list.forEach((results) => {
                results.index = count;
                count++
              });

            });


            this.is_show_1 = true;

            rate_1 = this.assessment.find((result)=> result.rate_level == 1 )
            rate_2 = this.assessment.find((result)=> result.rate_level == 2 )
            rate_3 = this.assessment.find((result)=> result.rate_level == 3 )

            rate_4 = this.assessment.find((result)=> result.rate_level == 4 )
            rate_5 = this.assessment.find((result)=> result.rate_level == 5 )
            rate_6 = this.assessment.find((result)=> result.rate_level == 6 )



            if(rate_1){
              let rate_1_total_j = (JSON.parse(rate_1.data));
              let rate_total_1 = 0;
              rate_1_total_j.forEach((result)=>{
                rate_total_1 += result.val;
              });
              this.rate_1 = rate_total_1+"/"+(JSON.parse(rate_1.data).length*5)
            }

            if(rate_2){
              let rate_2_total_j = (JSON.parse(rate_2.data));
              let rate_total_2 = 0;
              rate_2_total_j.forEach((result)=>{
                rate_total_2 += result.val;
              });

              this.rate_2 = rate_total_2+"/"+(JSON.parse(rate_2.data).length*5)
            }

            if(rate_3){
              let rate_3_total_j = (JSON.parse(rate_3.data));
              let rate_total_3 = 0;
              rate_3_total_j.forEach((result)=>{
                rate_total_3 += result.val;
              });

              this.rate_3 = rate_total_3+"/"+(JSON.parse(rate_3.data).length*5)
            }

            if(rate_4){
              let rate_4_total_j = (JSON.parse(rate_4.data));
              let rate_total_4 = 0;
              rate_4_total_j.forEach((result)=>{
                rate_total_4 += result.val;
              });

              this.rate_4 = rate_total_4+"/"+(JSON.parse(rate_4.data).length*5)
            }

            if(rate_5){
              let rate_5_total_j = (JSON.parse(rate_5.data));
              let rate_total_5 = 0;
              rate_5_total_j.forEach((result)=>{
                rate_total_5 += result.val;
              });


              this.rate_5 = rate_total_5+"/"+(JSON.parse(rate_5.data).length*5)
            }


            if(rate_6){
              let rate_6_total_j = (JSON.parse(rate_6.data));
              let rate_total_6 = 0;
              rate_6_total_j.forEach((result)=>{
                rate_total_6 += result.val;
              });

              this.rate_6 = rate_total_6+"/"+(JSON.parse(rate_6.data).length*5)
            }


            this.count = count;


            this.$refs.topProgress.done()
            break;
          default:

            this.is_loading = false;
            break;
        }

      });
    },
  },
  async mounted() {
    await this.GetData()
  }
}
</script>

<style scoped>
.thumb_profile{
  width: 100%;
  border-radius: 50%;
  box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.43);
  -webkit-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.43);
  -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.43);
}
.list_card{
  padding: 1rem;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  color: #1c1c1c;
}
.btn_more{
  font-size: 0.9rem;
  padding: 0.5rem;
  background: #058efa;
  color: white;
  border: 2px solid #5bb0f3;
  border-radius: 10px;
}
.line{
  border: 1px solid #00000014;
}
.list_box{
  display: flex;
  justify-content: space-around;
  gap: 5px;
}
.box-data{
  padding: 0.5rem;
  border: 1px solid #20aa14;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 5px;
  font-size: 0.8rem;
  cursor: pointer;
}
.thumb_list{
  position: relative;
}
.thumb_school{
  width: 30%;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
